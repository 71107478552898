@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html, body {
  @apply h-full;
}

@keyframes fade-in {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.1s ease-out;
}

@keyframes fade-in-down {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 0.1s ease-out;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  @apply antialiased flex flex-col;
}

.no-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.global-search-scroll {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
.global-search-scroll::-webkit-scrollbar {
  width: 12px;
}

.global-search-scroll::-webkit-scrollbar-track {
  background: #ffffff;
}

.global-search-scroll::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

@media only screen and (min-width: 1690px) {
  .player-login-card {
    @apply flex;
  } 
}

/* Freshdesk chat bot button */
#fc_frame {
  bottom: 50px !important;
  right: 50% !important;
  transform: translate(45%, 0%) !important;
  z-index: 50 !important;
}

#fc_frame.fc-open {
  transform: none !important;
}

@media only screen and (min-width: 640px) {
  #fc_frame {
    bottom: 50px !important;
    right: 36px !important;
    transform: translate(2%, 0%) !important;
    z-index: 50 !important;
  }
}
body.modal-active #fc_frame {
  z-index: 10 !important;
}

@keyframes shimmer {
  0% {
    background-position: 0 200%;
  }
  100% {
    background-position: 0 -200%;
  }
}

.animate-shimmer {
  animation: shimmer 2.5s infinite linear;
  background-size: 100% 200%;
}

/* Print score sheets for leagues game day with 2 options, print results card per paper or 2 results on 1 paper */
@media print {
  /* Half Sheet: One match per 8.5" x 5.5" */
  .print-half-sheet {
    width: 8.5in;
    height: 5.5in;
    page-break-after: always;
    padding-top: 40px; 
  }

  /* Full Sheet: Two matches per 8.5" x 11" */
  .print-full-sheet {
    width: 8.5in;
    height: 5.5in;
    display: flex;
    flex-direction: column;
    page-break-inside: avoid;
    padding-top: 40px; 
    margin: 0;
  }

  /* For full-sheet layout, reset the margin between pages */
  .print-full-sheet:nth-of-type(odd) {
    margin-top: 0;
  }

  .print-full-sheet:nth-of-type(even) {
    margin-top: 0;
  }

  /* Prevent any extra space at the top of the first form on each page */
  .print-full-sheet:first-of-type {
    margin-top: 0;
  }
}